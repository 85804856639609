import { get, post, put } from "./_utils";

export const upsertDraftSubmission = (formId, submissionId, data) => {
  return put(`/forms/${formId}/draft_submissions/${submissionId}.json`, data);
};

export const bulkDestroyDraftSubmissions = (formId, data) => {
  let postData = { submission_ids: data };
  return post(`/forms/${formId}/draft_submissions/bulk_destroy`, postData);
};

export const getDraftSubmissionData = (formId, draftSubmissionId) => {
  return get(`/forms/${formId}/draft_submissions/${draftSubmissionId}.json`);
};
